<template>
    <div class="product-image">
        <NuxtImg
            :sizes="sizes"
            densities="1x 2x"
            :loading="lazy ? 'lazy' : 'eager'"
            :src="
                image?.original || image?.src
                    ? image?.original || image?.src
                    : '#'
            "
            :alt="props.productTitle || image?.alt"
            format="webp"
            class="product-image__image"
            :placeholder="[300, 200, 60, 2]"
        />
        <div
            class="d-flex flex-wrap-reverse align-items-center product-image__labels"
            v-if="newIcon || offer || special || classic"
        >
            <img
                src="/images/product/Label-New-Height-Equal.svg"
                alt="new"
                loading="lazy"
                v-if="newIcon"
            />
            <img
                src="/images/product/Label-Offer-Height-Equal.svg"
                alt="offer"
                loading="lazy"
                v-if="offer"
            />
            <img
                src="/images/product/Label-Special-Height-Equal.svg"
                alt="special"
                loading="lazy"
                v-if="special"
            />
            <img
                src="/images/product/Label-Classic-Height-Equal.svg"
                alt="classic"
                loading="lazy"
                v-if="classic"
            />
        </div>
        <div class="product-image__organism" v-if="organism">
            <img
                loading="lazy"
                src="/images/product/Label-Stopper-Organism-DE.svg"
                alt="organism-de"
            />
        </div>
        <div
            class="d-flex align-items-center product-image__sold-out"
            v-if="soldOut"
        >
            <img
                :src="soldOutImage"
                alt="sold out"
                loading="lazy"
                class="img-fluid"
            />
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
    image: {
        type: Object,
        required: true,
    },
    new: {
        type: Boolean,
        default: false,
    },
    offer: {
        type: Boolean,
        default: false,
    },
    classic: {
        type: Boolean,
        default: false,
    },
    special: {
        type: Boolean,
        default: false,
    },
    organism: {
        type: Boolean,
        default: false,
    },
    soldOut: {
        type: Boolean,
        default: false,
    },
    productTitle: {
        type: String,
        default: '',
    },
    lazy: {
        default: true,
        type: Boolean,
    },
    sizes: {
        default: 'sm:540px md:920px lg:1200px',
        required: false,
    },
});

const newIcon = ref(props.new);
const img = useImage();
const { locale } = useI18n();

const soldOutImage = computed(() => {
    if (locale.value === 'fr') {
        return '/images/product/Label-Sold-Out-FR.svg';
    }
    return '/images/product/Label-Sold-Out-DE.svg';
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.product-image {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 150px;
    position: relative;
    overflow: hidden;

    &__image {
        width: 100%;
        object-fit: cover;
        min-width: 100%;
        min-height: 100%;
    }

    &__labels {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        z-index: 10;
        padding: 10px;

        img {
            height: clamp(30px, 1.04vw + 26.67px, 45px);

            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }

    &__organism {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
        padding: 10px;

        img {
            max-height: clamp(60px, 2.78vw + 51.11px, 100px);
            max-width: clamp(60px, 2.78vw + 51.11px, 100px);
            height: 100%;
            width: 100%;
        }
    }

    &__sold-out {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        right: 0;
        z-index: 20;
        background-color: rgba(0, 0, 0, 0.8);

        img {
            display: flex;
            max-height: 253px;
            max-width: 395px;
            object-fit: contain;
            margin: auto;
            // safari fix
            height: 100%;
            width: 100%;

            @include media-breakpoint-down(640) {
                max-height: 100%;
                max-width: 100%;
            }
        }
    }
}
</style>
