import { useIntersectionObserver } from '@vueuse/core';

export function useElementVisibility(element, options = {}) {
    const { scrollTarget } = options;
    const elementIsVisible = ref(false);
    if (!process.client) {
        return;
    }

    useIntersectionObserver(
        element,
        (intersectionObserverEntries) => {
            let isIntersecting = elementIsVisible.value;

            // Get the latest value of isIntersecting based on the entry time
            let latestTime = 0;
            for (const entry of intersectionObserverEntries) {
                if (entry.time >= latestTime) {
                    latestTime = entry.time;
                    isIntersecting = entry.isIntersecting;
                }
            }
            elementIsVisible.value = isIntersecting;
        },
        {
            root: scrollTarget,
            window,
            rootMargin: '0px 0px 100% 0px',
            threshold: 0,
        },
    );

    return elementIsVisible;
}
