import once from 'lodash.once';
import { useOscarClient } from './useOscarClient';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from '~/stores/auth';

export function useAvailability(load, product) {
    const loading = ref(false);
    const success = ref(null);
    const availabilities = ref([]);
    const parentPrice = ref(null);
    const authStore = useAuthStore();
    const { specialPrice } = storeToRefs(authStore);
    const loaded = computed(() => {
        return availabilities.value.length === productIds.length;
    });
    const { locale } = useI18n();
    const oscar = useOscarClient(locale.value);
    const isAvailable = computed(() => {
        return availabilities.value?.length
            ? availabilities.value.some((a) => a.is_available_to_buy)
            : true;
    });
    const productIds = [];
    if (product.structure === 'standalone') {
        productIds.push(product.id);
    } else {
        product?.children?.forEach((child) => productIds.push(child.id));
    }

    function fetchData() {
        loading.value = true;
        if (!loaded.value) {
            if (product.structure === 'parent') {
                oscar
                    .getAvailability(product.id, toValue(specialPrice))
                    .then((res) => {
                        parentPrice.value = res.data;
                    })
                    .catch((err) => {
                        console.log(
                            'Could not load availability parent data ' +
                                product.id,
                        );
                    });
            }
            productIds.forEach((productId) => {
                oscar
                    .getAvailability(productId, toValue(specialPrice))
                    .then((res) => {
                        loading.value = false;
                        success.value = true;
                        availabilities.value.push({
                            id: productId,
                            ...res.data,
                        });
                    })
                    .catch((err) => {
                        loading.value = false;
                        success.value = false;
                    });
            });
        }
    }

    const fetchDataOnce = once(fetchData);

    if (process.client) {
        if (toValue(load)) {
            fetchDataOnce();
        }
        watch(load, (newVal) => {
            if (newVal) {
                fetchDataOnce();
            }
        });
        watch(specialPrice, (newVal, oldVal) => {
            if (newVal && toValue(load)) {
                availabilities.value = [];
                fetchData();
            }
        });
    }

    return {
        loading,
        success,
        availabilities,
        loaded,
        isAvailable,
        parentPrice,
    };
}
